import React, {useState} from "react";
import { BsWhatsapp } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";


export const Footer = () => {
    const navigate = useNavigate()
    const handleOpenWhatsApp = () => {
        window.open('https://wa.me/+2349061229992', '_blank');
      };

      
    return (
        <div className="bg-blue-950 p-6 md:p-8 text-center text-white">
            <h2 className="text-4xl md:text-5xl font-bold mb-4">softnixx 1.0</h2>
            <p className="text-lg mb-4">Contact Us via the following</p>
            <button onClick={handleOpenWhatsApp}> <BsWhatsapp
            
            className="text-6xl md:text-7xl mx-auto mb-4 text-white shadow-lg" />
            </button>
          
            <div className="mb-6">
                <h3 className="text-2xl md:text-3xl font-semibold mb-2">Download the App and Enjoy Benefits Now</h3>
                <a href="#download" className="bg-blue-600 text-white text-lg font-semibold py-2 px-6 rounded-lg shadow hover:bg-blue-700 transition duration-300">
                    Download Now
                </a>
            </div>

            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-6 md:gap-12 mt-6">
                <div className="flex flex-col items-center">
                    <div className="inline-flex gap-1 items-center mb-2">
                        <h2 className="text-4xl md:text-5xl font-semibold">1</h2>
                        <h2 className="text-4xl md:text-5xl font-semibold">k</h2>
                    </div>
                    <h4 className="text-sm md:text-lg">Daily Transactions</h4>
                </div>

                <div className="flex flex-col items-center">
                    <div className="inline-flex gap-1 items-center mb-2">
                        <h2 className="text-4xl md:text-5xl font-semibold">10</h2>
                        <h2 className="text-4xl md:text-5xl font-semibold">+</h2>
                    </div>
                    <h4 className="text-sm md:text-lg">Monthly Giveaways</h4>
                </div>

                <div className="flex flex-col items-center">
                    <div className="inline-flex gap-1 items-center mb-2">
                        <h2 className="text-4xl md:text-5xl font-semibold">1K</h2>
                        <h2 className="text-4xl md:text-5xl font-semibold">+</h2>
                    </div>
                    <h4 className="text-sm md:text-lg">Gifts Claimed</h4>
                </div>
            </div>
        </div>
    );
};


export const LandingPage = () => {
    const navigate = useNavigate()
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    const handleOpenWhatsApp = () => {
        window.open('https://wa.me/+2349061229992', '_blank');
      };
    return (
        <div className="container lg:ml-32 p-auto bg-white w-auto text-darkblue shadow-lg rounded-lg">
         
            {/* Header Section */}
           
      <button onClick={handleOpenWhatsApp} className="fixed top-1/2">
        <FaWhatsapp className='text-green-950 text-9xl'  />
      </button>

            {/* Hero Section */}
            <div className="w-full flex flex-col z-10 px-4 py-8 mt-20">
                <h4 className="text-lg font-semibold text-blue-950 xl:text-2xl">Hey There</h4>
                <h1 className="xl:text-8xl sm:text-6xl xs:text-3xl font-semibold font-serif mt-2">
                    <span className="text-gray-950">I'M Sonita</span>
                </h1>
                <h1 className="sm:text-6xl xs:text-3xl text-gray-950 font-semibold font-serif">AI For Softnixx</h1>
                <h4 className="text-blue-950 mt-4 lg:text-2xl">-- you can ask me any question about this product.</h4>
                <p className="lg:w-[70%] xs:w-full text-red-950 text-sm mt-4 xl:text-xl">
                    I exist just for your sake. Please ask me anything related to this product; I will answer accurately.
                </p>
                <div>
                    <button onClick={()=>navigate("/ai")} className="mt-6 px-6 py-2 bg-blue-500 text-lg text-white rounded-lg lg:text-2xl hover:bg-rose-600 transition duration-300">
                        Click here let discuss
                    </button>
                </div>
            </div>

            {/* Stats Section */}




            {/* Information Section */}
            <div className="p-6 bg-white text-gray-900 font-serif rounded-lg shadow-md">
                <h2 className="text-4xl font-bold mb-4 text-center">What is Softnixx?</h2>
                <p className="text-lg mb-4 text-justify">
                    Softnixx functions like a combination of a local and a medium-sized bank, allowing you to manage all your utilities and withdraw money within minutes. Our platform offers a clean user interface for easy interactions and ensures lightning-fast communication with our API for an optimal user experience.
                </p>
                <p className="text-lg mb-4">
                    Use the download button to get started.
                </p>
                <div className="mt-6 text-center">
                    <a href="#download" className="inline-block px-6 py-3 bg-blue-600 text-white text-lg font-semibold rounded-lg shadow hover:bg-blue-700 transition duration-300">
                        Download Now
                    </a>
                </div>

                {/* Features Section */}
                <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <div className="flex flex-row justify-center items-center mb-4">
                            <h2 className="text-blue-950 text-3xl font-semibold mr-2">Utilities Management:</h2>
                            <FaCheckCircle className="text-blue-950 text-3xl" />
                        </div>
                        <p>Our mission is to reach out to our customers by providing an instant and reliable utilities management platform.</p>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <div className="flex flex-row justify-center items-center mb-4">
                            <h2 className="text-blue-950 text-3xl font-semibold mr-2">Cash Gift</h2>
                            <FaCheckCircle className="text-blue-950 text-3xl" />
                        </div>
                        <p>
                            We have created a function where you can manage and receive extra gifts, and potentially earn more on the app. Terms and conditions apply, read more <a href="#" className="text-blue-600 hover:underline">here</a>.
                        </p>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <div className="flex flex-row justify-center items-center mb-4">
                            <h2 className="text-blue-950 text-3xl font-semibold mr-2">Management of Funds</h2>
                            <FaCheckCircle className="text-blue-950 text-3xl" />
                        </div>
                        <ul className="list-disc pl-6">
                            <li>Transfer to other banks(On hold)</li>
                            <li>Transfer to Softnixx account</li>
                            <li>Deposit money into your account</li>
                        </ul>
                        <p className="mt-4">Funds transfer and management are guided by our rules and regulations. Check the terms and conditions <a href="#" className="text-blue-600 hover:underline">here</a>.</p>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <div className="flex flex-row justify-center items-center mb-4">
                            <h2 className="text-blue-950 text-3xl font-semibold mr-2">How It Works 1</h2>
                            <FaCheckCircle className="text-blue-950 text-3xl" />
                        </div>
                        <ul className="list-disc pl-6">
                            <li>Fund your wallet</li>
                            <li>Pay for your utilities</li>
                            <li>Get receipts</li>
                            <li>Only successful transactions will be debited for your utilities payment; otherwise, you need to try again.</li>
                        </ul>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <div className="flex flex-row justify-center items-center mb-4">
                            <h2 className="text-blue-950 text-3xl font-semibold mr-2">How It Works 2</h2>
                            <FaCheckCircle className="text-blue-950 text-3xl" />
                        </div>
                        <ul className="list-disc pl-6">
                            <li>We have an inbuilt function to help you make sales in your respective market structure, whether medium or small-scale business.</li>
                            <li>Navigate to the Extra page</li>
                            <li>Click on the plus sign</li>
                            <li>Upload your product with the specified guidelines</li>
                            <li>Wait for customers to contact you.</li>
                        </ul>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <div className="flex flex-row justify-center items-center mb-4">
                            <h2 className="text-blue-950 text-3xl font-semibold mr-2">How It Works 3</h2>
                            <FaCheckCircle className="text-blue-950 text-3xl" />
                        </div>
                        <ul className="list-disc pl-6">
                            <li>P2P Transactions</li>
                            <li>Offers for Airtime Recharge and Data</li>
                            <li>Click on the P2P</li>
                            <li>Upload your ADS with the specified guidelines</li>
                            <li>Wait for customers to contact you.</li>
                        </ul>
                    </div>
                </div>
            </div>
           
        </div>
    );
};
