import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useAlert } from '../../../alert/useAlert';

export const SendMessageDirect = () => {
    const [formData, setFormData] = useState({
        letter1: '',
        letter2: '',
        header: '',
    });

    const [loading, setLoading] = useState(false);
    const showMessage = useAlert(); // Assuming you have a showAlert function for alerts

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        try {
            const response = await fetch('https://www.softnixx.com/api/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`, // Assuming token is needed
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            showMessage('Message sent successfully!', 'success');
            setFormData({
                letter1: '',
                letter2: '',
                header: '',
            });
        } catch (error) {
 
            showMessage('Failed to send message', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-6 max-w-2xl mx-auto bg-white border border-gray-300 rounded-lg shadow-md">
            <h1 className="text-2xl font-bold mb-6 text-gray-900">Send Message</h1>
            <form onSubmit={handleSubmit} className="space-y-6">
                {/* Header Input */}
                <div className="flex flex-col">
                    <label htmlFor="header" className="font-semibold text-gray-700 mb-2">Header:</label>
                    <input
                        type="text"
                        id="header"
                        name="header"
                        value={formData.header}
                        onChange={handleInputChange}
                        placeholder="Enter the header here..."
                        className="border border-gray-300 rounded-lg p-2 w-full"
                        required
                    />
                </div>

                {/* First Message Input */}
                <div className="flex flex-col">
                    <label htmlFor="letter1" className="font-semibold text-gray-700 mb-2">First Message:</label>
                    <input
                        type="text"
                        id="letter1"
                        name="letter1"
                        value={formData.letter1}
                        onChange={handleInputChange}
                        placeholder="Enter the first message here..."
                        className="border border-gray-300 rounded-lg p-2 w-full"
                        required
                    />
                </div>

                {/* Extended Message Input */}
                <div className="flex flex-col">
                    <label htmlFor="letter2" className="font-semibold text-gray-700 mb-2">Extended Message:</label>
                    <input
                        type="text"
                        id="letter2"
                        name="letter2"
                        value={formData.letter2}
                        onChange={handleInputChange}
                        placeholder="Enter the extended message here..."
                        className="border border-gray-300 rounded-lg p-2 w-full"
                        required
                    />
                </div>

                {/* Submit Button */}
                <button
                    type="submit"
                    className={`w-full rounded-lg p-3 text-white font-semibold ${loading ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'} transition`}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : 'Send Message'}
                </button>
            </form>
        </div>
    );
};
