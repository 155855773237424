import React, { useState, useEffect } from "react";
import { PaystackButton } from "react-paystack";
import { useLocation, useNavigate } from "react-router-dom";
import { useAlert } from "../../component/alert/useAlert";
import { TextField, Button } from "@mui/material";

export const Paystack = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const showMessage = useAlert()
    const navigate = useNavigate()
  const location = useLocation();
  const token = localStorage.getItem('token');
    const username = localStorage.getItem('user');


  useEffect(() => {
    const getDetails = async () => {
        if (!token || !username) {
            showMessage('Something missing..', 'error');
            return;
        }
        setLoading(true);
        const itemUrl = `https://www.softnixx.com/api/updateD/${username}/`;
        try {
            const response = await fetch(itemUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Token ${token}`,
                },
            });

            if (!response.ok) {
                showMessage('Check your network connections!', 'error');
                return;
            }
            const json = await response.json();
            setData(json);
        } catch (error) {
            showMessage('Not allowed to view this page...', 'error');
        } finally {
            setLoading(false);
        }
    };

    getDetails();
}, []);


  const amount = location.state?.value || 0; 
  const apiUrl = "https://www.softnixx.com/api/transac/";
  const userId = data?.id; 

  const publicKey = "pk_live_46758e921078ab1b44d7e515c862b337ad6968d0"; 
  const email = data?.email; 
  const phone = data?.profile?.phone_number; 


  const componentProps = {
    email,
    amount: amount * 100, 
    metadata: {
      phone,
    },
    publicKey,
    text: `Pay Now ${amount}` ,
    onSuccess: async (response) => {
      try {
        const res = await fetch(apiUrl, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Authorization': `Token ${token}`,
          },
          body: JSON.stringify({
            token: token,
            amount: amount,
            id: userId,
          }),
        });
        showMessage("payement succesfull","error")
        if (!res.ok) {
          showMessage("something went wrong please contact us.")
        }
        const data = await res.json();
        showMessage("Transaction successful!", "success"); 
      } catch (error) {
        console.error("Fetch error:", error);
        showMessage("There was a problem with your transaction. Please try again.", "error");
      }
      finally{
        navigate('/home')
      }
    },
    onClose: () => showMessage("you can try again..", "error"),
  };

  return (
   
    <div className="container mx-auto p-6 bg-white text-darkblue shadow-lg rounded-lg max-w-md">
        {loading?<h5>Loading...please wait</h5>:
        <div>
      <h1 className="text-2xl font-bold mb-4 text-blue-700">Deposit Details</h1>
      <div className="mb-4 p-3 bg-blue-100 text-blue-700 rounded-lg">
        <p>Your personal SN {data?.profile?.tract_id}</p>
      </div>
      <div className="mb-4 p-3 bg-blue-100 text-blue-700 rounded-lg">
        <p>You are about to deposit into ({data?.username}) Account</p>
      </div>
      <TextField
        label={email}
        fullWidth
        variant="outlined"
        margin="normal"
        disabled
      />
      <TextField
        label={phone}
        fullWidth
        variant="outlined"
        margin="normal"
        disabled
      />
      <TextField
        label={`NGN ${amount}`}
      
        fullWidth
        variant="outlined"
        margin="normal"
        disabled
      />
      <PaystackButton
        className="mt-4 w-full bg-blue-900 rounded-xl text-white py-3"
        {...componentProps}
      />
      <div className="text-blue-900 text-center mt-6">
        This page is provided by Softnixx
      </div>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => navigate("/home")}
        className="w-full mt-4"
      >
        Cancel
      </Button>

      </div>
      }
    </div>
  );
};
