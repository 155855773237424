import React, { useState, useEffect } from 'react';
import { FaRocket, FaSpeakap } from "react-icons/fa";
import { motion } from "framer-motion"; // For animations
import { useNavigate } from "react-router-dom";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { CircularProgress } from '@mui/material';
import { useAlert } from '../alert/useAlert';

export const Finance = () => {
    const [loading, setLoading] = useState(false);
    const [barChartData, setBarChartData] = useState([]);
    const [suggestedAmount, setSuggestedAmount] = useState(null);
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const showMessage = useAlert()

    useEffect(() => {
        const getDetails = async () => {
            setLoading(true);
            try {
                const itemUrl = `https://www.softnixx.com/api/generate_receipt/`;
                const response = await fetch(itemUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Token ${token}`,
                    },
                });

                const json = await response.json();
                

                // Transform data for Bar Chart
                const barData = json.map(item => ({
                    name: item.name, // Example: Date or Category
                    amount: item.amount // Metric to display
                }));
                setBarChartData(barData);

                // Calculate average amount to suggest
                const totalAmount = barData.reduce((acc, item) => acc + item.amount, 0);
                const averageAmount = (totalAmount / barData.length) || 0;
                setSuggestedAmount(averageAmount);
            } catch (error) {
                showMessage("something went wrong","error")
            } finally {
                setLoading(false);
            }
        };

        getDetails();
    }, [token]);

    return (
        <div className="flex items-center justify-center min-h-screen bg-white">
            <div className="text-center p-6 bg-white shadow-lg rounded-lg w-full max-w-md">
               
                <h1 className="text-3xl font-bold text-gray-900 mb-2">Finance Overview</h1>
                <p className="text-lg text-gray-700 mb-4">
                    Here's an overview of your financial data.
                </p>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <div className="w-full h-96 mb-6">
                        <ResponsiveContainer>
                            <BarChart
                                data={barChartData}
                                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="amount" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                )}
                <p className="text-gray-600 mt-4">
                    Based on your Frequently purchase, we suggest buying an amount of <strong>N{suggestedAmount?.toFixed(2)}  for a week and you will be able to save {suggestedAmount?.toFixed(2)/4} in a week</strong>. 
                    <br />
                    To explore more options, please <button onClick={() => navigate('/')} className="text-blue-500 hover:underline">
                        Download App
                    </button>.
                </p>
                <div className="mt-6">
                    <h3 className="text-lg font-semibold">You made {barChartData.length} transactions</h3>
                    <p className="text-gray-700">Other features on our mobile App</p>
                </div>
            </div>
        </div>
    );
};
