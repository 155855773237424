
import React, { useState, useEffect } from "react";
import { FaMailBulk, FaWhatsapp } from "react-icons/fa";
import { FaExplosion } from "react-icons/fa6";



import { useAlert } from "../../component/alert/useAlert";
import { TextField } from "@mui/material";


export const Profile = () => {
const showMessage = useAlert()
const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
const token = localStorage.getItem("token");
const username = localStorage.getItem("user");

const getDetails = async () => {
  if (!token || !username) {
    showMessage("something missing..", "error")
    return;
  }

  setLoading(true);
  const itemUrL = `https://www.softnixx.com/api/updateD/${username}/`;
  try {
    const response = await fetch(itemUrL, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
    });

  
    if (!response.ok) {
     showMessage("check your network connections!", "error")
    }
    const json = await response.json();
    setData(json);
  } catch (error) {
    showMessage("Not allowed to view this page...", "error")
  } finally {
    setLoading(false);
  }
};


useEffect(() => {
  getDetails();
}, []); 
  return (
    <div className="container lg:ml-0 ml-0 p-1 bg-white w-auto text-darkblue shadow-lg rounded-lg">
        <marquee className=" text-red-500">
            Please use mobile app to update your profile
            </marquee>
      <h1 className="text-xl font-mono text-center mb-4">{data?.username?.toUpperCase()+"'"+ "profile"}</h1>
      <div className="flex flex-col items-center space-y-4">
        <img
          src={data?.profile?.ProfilePic}
          alt="Profile Picture"
          className="w-32 h-32 rounded-full object-cover bg-gray-50"
        />
        <div className="bg-slate-950 p-4 rounded-full flex items-center justify-center">
          <FaExplosion className="text-2xl text-white" />
        </div>
        <TextField
        label={data?.first_name?.toUpperCase() +"," +data?.last_name}
        fullWidth
        variant="outlined"
        margin="normal"
        disabled
      />
        <TextField
        label={data?.username}
        fullWidth
        variant="outlined"
        margin="normal"
        disabled
      />
      <TextField
        label={data?.email}
        fullWidth
        variant="outlined"
        margin="normal"
        disabled
      />
      </div>

    </div>
  );
};


