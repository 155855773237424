import React from 'react';
import { FaMobileAlt, FaTv, FaUserCircle, FaShieldAlt, FaCreditCard, FaGift, FaHeadset } from 'react-icons/fa';

export const AppOverview = () => {
    return (
        <div className="p-6 max-w-4xl mx-auto bg-white rounded-lg shadow-md">
            <h1 className="text-3xl font-bold text-gray-800 mb-6">
                Welcome to Our Telecom App!
            </h1>
            <p className="text-gray-600 mb-8">
                At Softellex, we're proud to offer reliable and efficient services. Our app makes it easy to manage your data, airtime, and cable recharges.
                We are a top telecom provider dedicated to giving our customers excellent connectivity solutions. With years of experience, we've become a trusted name, offering high-quality services that meet our customers' changing needs.
            </p>
            
            <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                    <FaMobileAlt className="inline mr-2 text-blue-600" />
                    OUR SERVICES
                </h2>
                <div className="space-y-6">
                    <div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">
                            <FaMobileAlt className="inline mr-2 text-blue-600" />
                            Data
                        </h3>
                        <p className="text-gray-600">
                            In this digital age, we know how important it is to stay connected. Our app makes it easy to buy and manage data packages that fit your needs. Whether it's for personal use, business, or staying in touch with loved ones, we offer a variety of data options to keep you connected.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">
                            <FaCreditCard className="inline mr-2 text-blue-600" />
                            Airtime
                        </h3>
                        <p className="text-gray-600">
                            Need to recharge your mobile phone quickly and easily? Our app lets you top-up your airtime conveniently, so you never run out of minutes or credit. With just a few clicks, you can recharge your airtime and keep making calls, sending messages, and surfing the web without interruptions.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">
                            <FaTv className="inline mr-2 text-blue-600" />
                            Cable Recharges
                        </h3>
                        <p className="text-gray-600">
                            Enjoy seamless entertainment with our cable recharge feature. Our app makes it easy to recharge your cable subscription so you can stay up-to-date with your favorite shows, movies, and sports events. Never miss a moment with our convenient cable recharge option.
                        </p>
                    </div>
                </div>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                    <FaUserCircle className="inline mr-2 text-blue-600" />
                    Key Features of Our App
                </h2>
                <div className="space-y-6">
                    <div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">
                            <FaUserCircle className="inline mr-2 text-blue-600" />
                            User-Friendly Interface
                        </h3>
                        <p className="text-gray-600">
                            Our app has a user-friendly interface that makes it easy to navigate and access the services you need. Whether you're new to using apps or an experienced user, our intuitive design ensures a hassle-free experience.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">
                            <FaShieldAlt className="inline mr-2 text-blue-600" />
                            Secure Transactions
                        </h3>
                        <p className="text-gray-600">
                            We prioritize the security of your personal and financial information. Our app uses advanced encryption technology to ensure that your transactions are safe and secure.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">
                            <FaMobileAlt className="inline mr-2 text-blue-600" />
                            Real-Time Usage Tracking
                        </h3>
                        <p className="text-gray-600">
                            Keep track of your data and airtime usage in real-time with our app. You can easily monitor your usage and avoid unexpected charges or running out of data. Our app provides detailed usage reports, allowing you to see which services consume the most data or airtime.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">
                            <FaCreditCard className="inline mr-2 text-blue-600" />
                            Multiple Payment Options
                        </h3>
                        <p className="text-gray-600">
                            We understand the importance of convenience and flexibility when it comes to payment. That's why our app offers multiple payment options, including credit/debit card payments, mobile wallets, and net banking. Choose the payment option that suits you best and complete your transaction hassle-free.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">
                            <FaGift className="inline mr-2 text-blue-600" />
                            Exclusive Offers and Deals
                        </h3>
                        <p className="text-gray-600">
                            As a valued customer, you'll have access to exclusive offers and deals through our app. Enjoy special discounts, cashback offers, and rewards for using our app for recharges and payments. We continuously update our app with new promotions to provide additional value to our customers.
                        </p>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">
                            <FaHeadset className="inline mr-2 text-blue-600" />
                            24/7 Customer Support
                        </h3>
                        <p className="text-gray-600">
                            Our dedicated customer support team is available 24/7 to assist you with any queries or issues you may have. Whether you need help with app functionality or troubleshooting, we're here to support you.
                        </p>
                    </div>
                </div>
            </section>

            <section>
                <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                    <FaUserCircle className="inline mr-2 text-blue-600" />
                    Summary
                </h2>
                <p className="text-gray-600">
                    In summary, our app offers convenient and hassle-free services such as mobile recharges, data recharges, cable recharges, and more. With a user-friendly interface, secure transactions, real-time usage tracking, multiple payment options, exclusive offers, and 24/7 customer support, our app ensures that you never run out of mobile credit or miss your favorite shows. Whether you're a new app user or an experienced digital user, our app makes it easy for you to stay connected and entertained.
                </p>
            </section>
        </div>
    );
};
