

import React from "react";
import './button.css'


export const Button=({title, handlSubmit})=>{

    return(
        <button onClick={handlSubmit} className="b-4">{title}</button>
    )
}