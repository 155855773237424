import React, {useState} from "react";
import Logo from '../../src/show.png';
import { FaBars } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import { useNavigate } from "react-router-dom";

export const Header =()=>{
    const navigate = useNavigate()
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    return(
        <header style={{
            paddingBottom:4,
        }} className="flex flex-row items-center fixed top-0 right-0 left-0 py-4 px-4 md:px-10 shadow-lg bg-white ">
            <div className="flex items-center w-full md:w-auto">
                <img className="w-[8rem] md:w-[10rem]" src={Logo} alt="Logo" />
            </div>
            <button
                onClick={toggleMenu}
                className="md:hidden flex items-center ml-auto"
                aria-label="Toggle menu"
            >
                <FaBars className="text-gray-700" />
            </button>
            <div className={`md:flex md:items-center md:w-auto w-full z-10 ${menuOpen ? 'block fixed top-0 left-0 bg-white shadow-lg w-full p-4' : 'hidden'}`}>
                {/* Close Button */}
                <button
                    onClick={toggleMenu}
                    className="absolute top-4 right-4 md:hidden flex items-center"
                    aria-label="Close menu"
                >
                    <CgClose className="text-gray-700" />
                </button>

                <nav className="w-full md:w-auto">
                    <ul className="flex flex-col md:flex-row items-start md:items-center text-base xl:text-lg text-gray-950 pt-4 md:pt-0">
                        <li><a className="md:p-4 py-3 px-0 block text-blue-950 hover:text-blue-700" href="/">Home</a></li>
                        <li><button className="md:p-4 py-3 px-0 block hover:text-blue-700" onClick={()=>navigate("developer")}>Developer</button></li>
                        <li><button className="md:p-4 py-3 px-0 block hover:text-blue-700" onClick={()=>navigate("terms")}>Terms and Conditions</button></li>
                        <li><button className="md:p-4 py-3 px-0 block hover:text-blue-700" onClick={()=>navigate("about")}>About Softnixx</button></li>
                        <li><button className="md:p-4 py-3 px-0 block hover:text-blue-700" onClick={()=>navigate("project")}>Projects/Api</button></li>
                        <li><button onClick={()=>navigate("/login")} className="md:p-4 py-3 px-0 block hover:text-blue-700 md:mb-0 mb-2" href="#">Login</button></li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}