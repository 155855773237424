import React, { useEffect, useState } from "react";
import { TextField, Button, MenuItem, Select, Input , CircularProgress} from "@mui/material";
import { Modal, Dialog, DialogContent, DialogActions } from "@mui/material";
import { useAlert } from "../../component/alert/useAlert";
import { BottomSheet } from "./GeneralOutput";

const generateRequestId = () => {
    const lagosTimezoneOffset = 60; // Lagos timezone is GMT+1, so the offset is 60 minutes
    const now = new Date();
    const lagosTime = new Date(now.getTime() + lagosTimezoneOffset * 60000);

    const year = lagosTime.getUTCFullYear().toString();
    const month = (lagosTime.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = lagosTime.getUTCDate().toString().padStart(2, '0');
    const hour = lagosTime.getUTCHours().toString().padStart(2, '0');
    const minute = lagosTime.getUTCMinutes().toString().padStart(2, '0');

    const requestIdPrefix = year + month + day + hour + minute;
    const additionalCharacters = 'ad8ef08acd8fc0f'; // Example additional characters
    const requestId = requestIdPrefix + additionalCharacters.slice(0, Math.max(0, 12 - requestIdPrefix.length));

    return requestId;
};

const generateRandomString = (length) => {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
};

const encode = (credentials) => {
    return btoa(credentials); // Base64 encoding
};

const ModalComponent = ({ open, amount, onClose, data, provider, phone, showMessage, setLoading, loading }) => {
    const [userPin, setUserPin] = useState('');

    const handleSubmit = async () => {
        if (userPin.length !== 4) {
            showMessage("Your PIN must be 4 digits", "error");
            return;
        }
        if (userPin.trim() === data.profile.transaction_pin && amount <= data.profile.account_balance) {
            const requestId = generateRequestId();
            const randomString = generateRandomString(12);
            const y_id = requestId + randomString;
            const email = "samuelyyyy257@gmail.com";
            const password = "antydamilove1";
            const credentials = `${email}:${password}`;
            const encodedCredentials = encode(credentials);
            const url = `https://api-service.vtpass.com/api/pay?serviceID=${provider}&request_id=${y_id}&amount=${amount}&phone=${phone}`;
            try {
                setLoading(true);
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Authorization": `Basic ${encodedCredentials}`,
                    },
                });
                const data = await response.json();
                
                if (data.content.transactions.status === "failed") {
                    showMessage("Payment Failed!", 'error');
                } else {
                    await fetch('https://www.softnixx.com/api/createR/', {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                            'Authorization': `Token ${localStorage.getItem('token')}`,
                        },
                        body: JSON.stringify({
                            amount: data.amount,
                            status: data.content.transactions.status,
                            reference: data.content.transactionId,
                            request_id: y_id,
                            name: 'Utility',
                        })
                    });
                    showMessage("Payment Successful", 'success');
                }
            } catch (error) {
                showMessage("An error occurred", "error");
            } finally {
                setLoading(false);
                onClose();
            }
        } else {
            showMessage("Error: Incorrect PIN or insufficient balance", "error");
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <div style={{ padding: '20px', backgroundColor: 'white', margin: 'auto', maxWidth: '500px', borderRadius: '8px' }}>
                <h2 id="modal-title">Enter 4-Digit PIN</h2>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Enter 4 digit Pin"
                    type='password'
                    fullWidth
                    variant="outlined"
                    value={userPin}
                    onChange={(e) => setUserPin(e.target.value)}
                    helperText="4 digit pin"
                />
                <p><strong> Amount:</strong> {amount}</p>
                <div style={{ marginTop: '20px', textAlign: 'right' }}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <Button onClick={onClose}>Cancel</Button>
                            <Button onClick={handleSubmit} disabled={!userPin}>
                                Submit
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ModalComponent;


export const Airtime = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [phone, setPhone] = useState('');
    const [amount, setAmount] = useState("");
    const [provider, setProvider] = useState("");
    const [open, setOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const showMessage = useAlert();
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('user');

    const dataCard = [
        { label: 'Mtn', value: 'mtn' },
        { label: 'Glo', value: 'glo' },
        { label: 'Etisalat', value: 'etisalat' },
        { label: 'Airtel', value: 'airtel' }
    ];

    useEffect(() => {
        const getDetails = async () => {
            if (!token || !username) {
                showMessage('Something missing..', 'error');
                return;
            }
            setLoading(true);
            const itemUrl = `https://www.softnixx.com/api/updateD/${username}/`;
            try {
                const response = await fetch(itemUrl, {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        Authorization: `Token ${token}`,
                    },
                });

                if (!response.ok) {
                    showMessage('Check your network connections!', 'error');
                    return;
                }
                const json = await response.json();
                setData(json);
            } catch (error) {
                showMessage('Not allowed to view this page...', 'error');
            } finally {
                setLoading(false);
            }
        };

        getDetails();
    }, [token, username, showMessage]);

    const handleSubmit = () => {
        if(data?.profile?.account_balance <amount){
            showMessage('Please fund your wallet', 'error');
            return;
        }
        if (!phone || !amount || !provider) {
            showMessage('Please fill all fields', 'error');
            return;
        }
        setOpen(true);
    };

    const handleModalClose = () => setOpen(false);
    const handleBottomSheetToggle = () => setIsOpen(!isOpen);

    const MoneyConvert = (num) => {
        return num?.toLocaleString();
    };

    return (
        <div className="container lg:ml-0 ml-0 p-1 bg-white w-auto text-darkblue shadow-lg rounded-lg">
            <h1 className="text-2xl font-bold mb-4">Recharge Your Airtime</h1>
            <div className="bg-darkblue text-white p-6 rounded-xl shadow-md mb-6">
                <div className="text-sm font-semibold">
                    <h3 className="text-xl  text-gray-700">Balance</h3>
                    <p className="text-2xl text-gray-700">N{data?.profile?.account_balance?.toLocaleString() || '0'}</p>
                </div>
            </div>
            <div className="mb-6">
                <label htmlFor="provider-select" className="block text-sm font-medium text-gray-700 mb-2">
                    Select Provider
                </label>
                <Select
                    value={provider}
                    onChange={(e) => setProvider(e.target.value)}
                    variant="outlined"
                    className="w-full"
                >
                    {dataCard.map((card) => (
                        <MenuItem key={card.value} value={card.value}>
                            {card.label}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div className="mb-6">
                <TextField
                    placeholder="Your phone number"
                    variant="outlined"
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="w-full mt-4"
                />
            </div>
            <div className="mb-6">
                <label htmlFor="plan-select" className="block text-sm font-medium text-gray-700 mb-2">
                    Select Amount
                </label>
                <div className="flex flex-wrap gap-2">
                    {[100, 200, 300, 400, 500, 1000, 2000].map((amt) => (
                        <Button
                            key={amt}
                            variant={amount === amt.toString() ? "contained" : "outlined"}
                            onClick={() => setAmount(amt.toString())}
                        >
                            N{amt.toLocaleString()}
                        </Button>
                    ))}
                </div>
                <Input
                    type="number"
                    placeholder="Enter amount"
                    value={MoneyConvert(amount)}
                    onChange={(e) => setAmount(e.target.value)}
                />
            </div>

            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={loading}
            >
                {loading ? 'Loading...' : 'Submit (Pay)'}
            </Button>
            <ModalComponent
                open={open}
                amount={amount}
                onClose={handleModalClose}
                loading={loading}
                data={data}
                provider={provider}
                phone={phone}
                showMessage={showMessage}
                setLoading ={()=>setLoading(!loading)}
                isOpen={handleBottomSheetToggle}
            />
            <BottomSheet
                phone={phone}
                price={amount}
                isOpen={isOpen}
                
            />
        </div>
    );
};
