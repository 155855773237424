import React from 'react';
import { motion } from 'framer-motion';
import { BsExclamationOctagonFill } from 'react-icons/bs';

const Notfound = () => {
  return (
    <motion.div
      className="flex flex-col items-center justify-center min-h-screen bg-gray-100"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div
        className="mb-6"
        initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <BsExclamationOctagonFill className="h-24 w-24 text-red-600" />
      </motion.div>
      <motion.h1
        className="text-6xl font-bold text-red-600"
        initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        404
      </motion.h1>
      <motion.p
        className="text-xl text-gray-700 mt-4"
        initial={{ y: 20 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Page Not Found
      </motion.p>
    </motion.div>
  );
};

export default Notfound;
