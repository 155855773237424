import React, { useState } from "react";
import { FaBars, FaClosedCaptioning } from "react-icons/fa";
import { Link, Outlet } from "react-router-dom";

export const AdminDashboard = () => {
  const isStaff = localStorage.getItem("staff") === "true"; // Ensure this is a boolean
  const [openToggle, setToggle] = useState(false);

  const onClikToggle = () => {
    setToggle(!openToggle);
  };

  return (
    <>
      {isStaff ? (
        <div className="flex flex-col lg:flex-row h-screen bg-gray-100">
          {/* Sidebar: Hidden on mobile and shown on larger screens */}
          <div
            className={`fixed top-0 left-0 z-10 w-full lg:w-64 bg-gray-800 text-white p-4 flex flex-col lg:relative lg:min-h-screen transition-transform duration-300 ${
              openToggle ? "translate-x-0" : "-translate-x-full"
            } lg:translate-x-0`}
          >
           
            <div className="flex items-center mb-6">
              <img
                src="https://via.placeholder.com/40"
                alt="User Avatar"
                className="w-12 h-12 rounded-full mr-3 border-2 border-gray-500"
              />
              <div>
                <h2 className="text-xl font-semibold">Admin</h2>
                <p className="text-gray-400 text-sm">support@softnixx.com</p>
              </div>
            </div>
            <h2 className="text-2xl font-bold mb-4">Softnixx 1.0</h2>
            <h2 className="text-xl font-bold mb-6">Admin Dashboard</h2>

            <input
              type="text"
              placeholder="Search..."
              className="mb-4 w-full px-3 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
            />
            <ul className="flex-1 space-y-3">
              <li>
                <Link onClick={onClikToggle} to="" className="block p-3 rounded-lg hover:bg-gray-700 transition duration-300 ease-in-out">Home</Link>
              </li>
              <li>
                <Link onClick={onClikToggle} to="find" className="block p-3 rounded-lg hover:bg-gray-700 transition duration-300 ease-in-out">Show Users</Link>
              </li>
              <li>
                <Link onClick={onClikToggle} to="receipts" className="block p-3 rounded-lg hover:bg-gray-700 transition duration-300 ease-in-out">Receipts</Link>
              </li>
              <li>
                <Link onClick={onClikToggle} to="send-message" className="block p-3 rounded-lg hover:bg-gray-700 transition duration-300 ease-in-out">Send Message</Link>
              </li>
            </ul>
          </div>
          
          {/* Main Content */}
          <div className="flex-1 p-6 lg:ml-64"> {/* Add margin-left to content area for sidebar */}
          <FaBars
              onClick={onClikToggle}
              className="lg:hidden text-white bg-blue-950 right-0 cursor-pointer mb-4"
            />
            <Outlet />
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-50">
          <FaClosedCaptioning size={50} className="text-gray-500 mb-4" />
          <h4 className="text-lg font-medium text-gray-600">Admin Not Found</h4>
        </div>
      )}
    </>
  );
};
