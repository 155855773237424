import React, { useState, useEffect } from "react";
import { giftdata } from "./dataplans";
import { useAlert } from "../../component/alert/useAlert";
import { BottomSheet } from "./GeneralOutput";
import {
  Modal,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const generateRequestID = () => {
  const timestamp = new Date().getTime();
  const random = Math.floor(Math.random() * 1000000);
  return `Data_${timestamp}${random}`;
};

const ModalComponent = ({
  open,
  onClose,
  data,
  selectedProvider,
  showMessage,
  token,
  phone,
  plan_id,
  planPrice,
  bottom,
}) => {
  const [userPin, setUserPin] = useState("");
  const [loading, setLoading] = useState(false);

  const value = planPrice.split("₦")[1] || "0";

  const handleSubmit = async () => {
    if (userPin.length !== 4) {
      showMessage("Your PIN must be 4 dig,its", "error");
      setLoading(false);
      return;
    }

    let planId;
    switch (selectedProvider) {
      case "MTN":
        planId = 1;
        break;
      case "AIRTEL":
        planId = 2;
        break;
      case "GLO":
        planId = 2;
        break;
      case "ETISALAT":
        planId = 4;
        break;
      default:
        planId = null;
    }

    if (
      userPin.trim() === data.profile.transaction_pin &&
      parseFloat(value) <= data.profile.account_balance
    ) {
      setLoading(true);
      const payload = {
        network: planId,
        phone: phone,
        data_plan: plan_id,
        bypass: false,
        "request-id": generateRequestID(),
      };

      const authHeader =
        "Token 764c20d5169ea982d692e273b417bf33275e70efc741949214525d6dd84f";

      try {
        const response = await fetch("https://n3tdata.com/api/data", {
          method: "POST",
          headers: {
            Authorization: authHeader,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        const result = await response.json();
        if (!response.ok) {
          showMessage(result.status, "error");
        } else if (result.status == "fail") {
          showMessage(result.status, "error");
        } else {
          showMessage(result.status, "success");
          await fetch("https://www.softnixx.com/api/createR/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Token ${token}`,
            },
            body: JSON.stringify({
              amount: result.amount,
              status: result.status,
              reference: generateRequestID(),
              request_id: generateRequestID(),
              name: "gift",
            }),
          });
          bottom(true);
        }
      } catch (error) {
        showMessage("An error occurred during the transaction", "error");
      } finally {
        setLoading(false);
        onClose();
      }
    } else {
      showMessage(
        "Please check your transaction PIN and wallet balance.",
        "error"
      );
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="input-field"
            label="Enter 4 digit Pin"
            type='password'
            fullWidth
            variant="outlined"
            value={userPin}
            helperText="4 digit pin"
            onChange={(e) => setUserPin(e.target.value)}
          />
          <div className="mt-4">
            <p>
              <strong>Plan Price:</strong> {planPrice}
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={handleSubmit} disabled={!userPin}>
                Submit
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Modal>
  );
};

export default ModalComponent;

export const Data = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [phone, setPhone] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedPlanPrice, setSelectedPlanPrice] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
  const showMessage = useAlert();
  const token = localStorage.getItem("token");
  const username = localStorage.getItem("user");

  useEffect(() => {
    const getDetails = async () => {
      if (!token || !username) {
        showMessage("Something missing..", "error");
        return;
      }
      setLoading(true);
      const itemUrl = `https://www.softnixx.com/api/updateD/${username}/`;
      try {
        const response = await fetch(itemUrl, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Token ${token}`,
          },
        });

        if (!response.ok) {
          showMessage("Check your network connections!", "error");
          return;
        }
        const json = await response.json();
        setData(json);
      } catch (error) {
        showMessage("Not allowed to view this page...", "error");
      } finally {
        setLoading(false);
      }
    };

    getDetails();
  }, [token, username, showMessage]);

  const providerNames = giftdata.map((provider) => provider.title);
  const plans =
    giftdata.find((provider) => provider.title === selectedProvider)?.data ||
    [];
  const handlePlanChange = (e) => {
    const selectedPlanId = parseInt(e.target.value, 10);
    const selectedPlanData = plans.find(
      (plan) => plan.plan_id === selectedPlanId
    );
    setSelectedPlan(selectedPlanId);
    setSelectedPlanPrice(selectedPlanData ? selectedPlanData.price : "");
  };

  const handleSubmit = () => {
    const value = selectedPlanPrice.split("₦")[1] || "0";
    const number = parseFloat(value.replace(/,/g, ""));
    if (data?.profile?.account_balance < number) {
      showMessage("Please fund your wallet", "error");
      return;
    }
    setIsOpen(true);
  };

  return (
    <div className="container lg:ml-0 ml-0 p-1 bg-white w-auto text-darkblue shadow-lg rounded-lg">
      <h1 className="text-2xl font-bold mb-4">Reconnect Your Data</h1>
      <div className="bg-darkblue text-white p-6 rounded-xl shadow-md mb-6">
        <div className="text-sm font-semibold">
          <h3 className="text-xl text-gray-700">Balance</h3>
          <p className="text-2xl text-gray-700">
            N{data?.profile?.account_balance?.toLocaleString() || "0"}
          </p>
        </div>
      </div>
      <div className="mb-6">
        <label
          htmlFor="provider-select"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Select Provider
        </label>
        <select
          id="provider-select"
          className="p-2 border border-gray-300 rounded-lg w-full"
          value={selectedProvider}
          onChange={(e) => setSelectedProvider(e.target.value)}
        >
          <option value="">Select Provider</option>
          {providerNames.map((name, index) => (
            <option key={index} value={name}>
              {name}
            </option>
          ))}
        </select>
        <TextField
          placeholder="Your phone number"
          variant="outlined"
          type="number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          className="w-full mt-4"
        />
      </div>
      <div className="mb-6">
        <label
          htmlFor="plan-select"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Select Plan
        </label>
        <select
          id="plan-select"
          className="p-2 border border-gray-300 rounded-lg w-full"
          value={selectedPlan}
          onChange={handlePlanChange}
          disabled={!selectedProvider}
        >
          <option value="">Select Plan</option>
          {plans.map((plan, index) => (
            <option key={index} value={plan.plan_id}>
              {plan.name} - {plan.price} ({plan.validity})
            </option>
          ))}
        </select>
      </div>
      <button
        className={`w-full p-2 rounded-lg text-blue-900 font-semibold ${
          selectedPlan
            ? "bg-darkblue hover:bg-blue-700"
            : "bg-gray-400 cursor-not-allowed"
        }`}
        disabled={!selectedPlan || loading}
        onClick={handleSubmit}
      >
        {loading ? "Loading..." : "Submit (Pay)"}
      </button>
      <ModalComponent
        open={isOpen}
        onClose={() => setIsOpen(false)}
        data={data}
        selectedProvider={selectedProvider}
        showMessage={showMessage}
        token={token}
        phone={phone}
        plan_id={selectedPlan}
        planPrice={selectedPlanPrice}
        bottom={setBottomSheetOpen}
      />
      <BottomSheet
        isOpen={bottomSheetOpen}
        plan={selectedPlan}
        phone={phone}
        price={selectedPlanPrice}
      />
    </div>
  );
};
