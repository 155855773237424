export const giftdata = [
   {
      title: "MTN",
      data: [
         { name: "SME 500MB", price: "₦140.00", validity: "1 Month", plan_id: 1 },
         { name: "SME 1GB", price: "₦270.00", validity: "1 Month", plan_id: 2 },
         { name: "SME 2GB", price: "₦530.00", validity: "1 Month", plan_id: 3 },
         { name: "SME 3GB", price: "₦790.00", validity: "1 Month", plan_id: 4 },
         { name: "SME 5GB", price: "₦1,400.00", validity: "1 Month", plan_id: 5 },
         { name: "SME 10GB", price: "₦2,700.00", validity: "1 Month", plan_id: 6 },
         { name: "COOPERATE GIFTING 500MB", price: "₦140.00", validity: "1 Month", plan_id: 50 },
         { name: "COOPERATE GIFTING 1GB", price: "₦270.00", validity: "1 Month", plan_id: 51 },
         { name: "COOPERATE GIFTING 2GB", price: "₦530.00", validity: "1 Month", plan_id: 52 },
         { name: "COOPERATE GIFTING 3GB", price: "₦790.00", validity: "1 Month", plan_id: 53 },
         { name: "COOPERATE GIFTING 5GB", price: "₦1,400.00", validity: "1 Month", plan_id: 54 },
         { name: "COOPERATE GIFTING 10GB", price: "₦2,700.00", validity: "1 Month", plan_id: 55 },
         { name: "GIFTING 500MB", price: "₦130.00", validity: "1 Month", plan_id: 69 },
         { name: "GIFTING 1GB", price: "₦250.00", validity: "1 Month", plan_id: 68 },
         { name: "GIFTING 2GB", price: "₦490.00", validity: "1 Month", plan_id: 67 },
         { name: "GIFTING 3GB", price: "₦730.00", validity: "1 Month", plan_id: 66 },
         { name: "GIFTING 5GB", price: "₦1,300.00", validity: "1 Month", plan_id: 65 },
         { name: "GIFTING 1GB", price: "₦229.00", validity: "1 Day Awoof Data", plan_id: 78 },
         { name: "GIFTING 3.5GB", price: "₦580.00", validity: "2 Days Awoof Data", plan_id: 79 },
         { name: "GIFTING 15GB", price: "₦2,060.00", validity: "7 Days Awoof Data", plan_id: 80 },
         { name: "GIFTING 10GB", price: "₦2,200.00", validity: "1 Month", plan_id: 81 }
      ]
   },
   {
      title: "GLO",
      data: [
         { name: "GIFTING 1.35GB", price: "₦490.00", validity: "1 Month", plan_id: 20 },
         { name: "GIFTING 2.9GB", price: "₦990.00", validity: "1 Month", plan_id: 21 },
         { name: "GIFTING 4.1GB", price: "₦1,490.00", validity: "1 Month", plan_id: 22 },
         { name: "GIFTING 5.8GB", price: "₦1,910.00", validity: "1 Month", plan_id: 24 },
         { name: "COOPERATE GIFTING 200MB", price: "₦60.00", validity: "1 Month", plan_id: 57 },
         { name: "COOPERATE GIFTING 500MB", price: "₦155.00", validity: "1 Month", plan_id: 58 },
         { name: "COOPERATE GIFTING 1GB", price: "₦295.00", validity: "1 Month", plan_id: 59 },
         { name: "COOPERATE GIFTING 2GB", price: "₦570.00", validity: "1 Month", plan_id: 60 },
         { name: "COOPERATE GIFTING 3GB", price: "₦890.00", validity: "1 Month", plan_id: 61 },
         { name: "COOPERATE GIFTING 5GB", price: "₦1,460.00", validity: "1 Month", plan_id: 62 },
         { name: "COOPERATE GIFTING 10GB", price: "₦2,950.00", validity: "1 Month", plan_id: 63 }
      ]
   },
   {
      title: "9MOBILE",
      data: [
         { name: "GIFTING 1.5GB", price: "₦990.00", validity: "1 Month", plan_id: 36 },
         { name: "GIFTING 2GB", price: "₦1,120.00", validity: "1 Month", plan_id: 37 },
         { name: "GIFTING 3GB", price: "₦1,410.00", validity: "1 Month", plan_id: 38 },
         { name: "GIFTING 4.5GB", price: "₦1,900.00", validity: "1 Month", plan_id: 39 },
         { name: "COOPERATE GIFTING 500MB", price: "₦110.00", validity: "1 Month", plan_id: 72 },
         { name: "COOPERATE GIFTING 1GB", price: "₦210.00", validity: "1 Month", plan_id: 73 },
         { name: "COOPERATE GIFTING 2GB", price: "₦410.00", validity: "1 Month", plan_id: 74 },
         { name: "COOPERATE GIFTING 3GB", price: "₦620.00", validity: "1 Month", plan_id: 75 },
         { name: "COOPERATE GIFTING 5GB", price: "₦1,040.00", validity: "1 Month", plan_id: 76 },
         { name: "COOPERATE GIFTING 10GB", price: "₦2,010.00", validity: "1 Month", plan_id: 77 }
      ]
   },
   {
      title: "AIRTEL",
      data: [
         { name: "COOPERATE GIFTING 500MB", price: "₦140.00", validity: "1 Month", plan_id: 46 },
         { name: "COOPERATE GIFTING 1GB", price: "₦280.00", validity: "1 Month", plan_id: 47 },
         { name: "COOPERATE GIFTING 2GB", price: "₦560.00", validity: "1 Month", plan_id: 48 },
         { name: "COOPERATE GIFTING 5GB", price: "₦1,400.00", validity: "1 Month", plan_id: 49 },
         { name: "COOPERATE GIFTING 10GB", price: "₦2,800.00", validity: "1 Month", plan_id: 56 },
         { name: "GIFTING 100MB", price: "₦90.00", validity: "1 Day Awoof Data", plan_id: 82 },
         { name: "GIFTING 300MB", price: "₦150.00", validity: "2 Days Awoof Data", plan_id: 83 },
         { name: "GIFTING 1GB", price: "₦250.00", validity: "2 Days Awoof Data", plan_id: 84 },
         { name: "GIFTING 2GB", price: "₦380.00", validity: "2 Days Awoof Data", plan_id: 85 },
         { name: "GIFTING 3GB", price: "₦610.00", validity: "7 Days Awoof Data", plan_id: 86 },
         { name: "GIFTING 4GB", price: "₦1,200.00", validity: "1 Month Awoof Data", plan_id: 87 },
         { name: "GIFTING 15GB", price: "₦3,250.00", validity: "1 Month Awoof Data", plan_id: 89 },
         { name: "GIFTING 10GB", price: "₦2,200.00", validity: "1 Month", plan_id: 88 }
      ]
   }
];

