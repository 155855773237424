import React, { useState } from 'react';

const SendMessage = () => {
    const [header, setHeader] = useState('');
    const [message1, setMessage1] = useState('');
    const [message2, setMessage2] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Logic to send the message (e.g., API call) goes here
        alert('Message sent!');
    };

    return (
        <div className="p-6 max-w-2xl mx-auto bg-white border border-gray-300 rounded-lg shadow-md">
            <h1 className="text-3xl font-bold mb-6 text-center">Send Message to Customers</h1>
            <form onSubmit={handleSubmit} className="space-y-6">
                {/* Header Input */}
                <div>
                    <label htmlFor="header" className="block text-gray-700 font-semibold mb-2">Header</label>
                    <input
                        type="text"
                        id="header"
                        value={header}
                        onChange={(e) => setHeader(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                        placeholder="Enter message header"
                        required
                    />
                </div>

                {/* Message 1 Input */}
                <div>
                    <label htmlFor="message1" className="block text-gray-700 font-semibold mb-2">Message 1</label>
                    <textarea
                        id="message1"
                        value={message1}
                        onChange={(e) => setMessage1(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                        placeholder="Enter first part of the message"
                        rows="4"
                        required
                    />
                </div>

                {/* Message 2 Input */}
                <div>
                    <label htmlFor="message2" className="block text-gray-700 font-semibold mb-2">Message 2</label>
                    <textarea
                        id="message2"
                        value={message2}
                        onChange={(e) => setMessage2(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                        placeholder="Enter second part of the message"
                        rows="4"
                    />
                </div>

                {/* Submit Button */}
                <div className="text-center">
                    <button
                        type="submit"
                        className="px-6 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Send Message
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SendMessage;
