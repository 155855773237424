import React, { useState, useEffect } from "react";
import '../home.css';
import { Button } from "@mui/material";
import { Allserv } from "./renderAllservices";
import ModalComponent from "./modal";
import { FaBars, FaCopy } from "react-icons/fa6";
import { FaHome, FaUser, FaCog, FaCopyright, FaMoneyBill, FaMotorcycle, FaPowerOff } from "react-icons/fa";
import { useAlert } from "../../component/alert/useAlert";
import { FaPlus } from 'react-icons/fa'; // Import the icon from react-icons
import { useNavigate } from "react-router-dom";

export const Service = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null); // Initialize as null instead of empty array
  const showMessage = useAlert();
  const [open, setOpen] = useState(false)

const navigate =useNavigate()

 
const Button = ({ className, onClick, children }) => (
  <button
    className={`flex items-center text-white py-2 px-4 rounded-lg bg-blue-950 hover:bg-blue-700 transition duration-300 ease-in-out ${className}`}
    onClick={onClick}
  >
    {children}
  </button>
);

  const ToggleOpen=()=>{
    setOpen(!open)
  }

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);


  const token = localStorage.getItem("token");
  const username = localStorage.getItem("user");

  const getDetails = async () => {
    if (!token || !username) {
      showMessage("Something is missing...", "error");
      return;
    }

    setLoading(true);
    const itemUrL = `https://www.softnixx.com/api/updateD/${username}/`;
    try {
      const response = await fetch(itemUrL, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const json = await response.json();
      setData(json);
    } catch (error) {
      showMessage(`Error: ${error.message}`, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDetails();
  }, []); // Empty dependency array to run only once on mount

  const formatLocaleString = (value) => {
    if (value != null) { // Check for both null and undefined
      return value.toLocaleString();
    }
    return 'N/A';
  };

  const copyToClipboard = (text) => {
    try{
      if (text) {
        navigator.clipboard.writeText(text)
          .then(() => showMessage('Text copied to clipboard', "success"))
          .catch(error => showMessage(`Failed to copy text: ${error.message}`, 'error'));
      } else {
        showMessage('No text to copy', 'error');
      }
    }
    catch{
      showMessage('No text to copy', 'error');
    }
   
  };


  const handleAll=(path)=>{
navigate(path)
setOpen(false)
  }


const Logout =() =>{
  localStorage.clear()
  showMessage("Logout succesfully..!","error")
  navigate("/login")
}


  return (
    <>
    {open && (
      <div className="bg-blue-900 fixed top-0 left-0 w-64 h-full shadow-lg p-6">
        <h1 className="text-white text-2xl font-bold mb-8">{data?.username} Profile</h1>
        <ul className="space-y-4 text-white">
          <li className="flex items-center space-x-3 p-2 hover:bg-blue-700 rounded-md cursor-pointer transition-colors duration-300">
            <FaHome className="text-lg" />
            <span>Main</span>
          </li>
          <li className="flex items-center space-x-3 p-2 hover:bg-blue-700 rounded-md cursor-pointer transition-colors duration-300">
            <FaUser className="text-lg" />
            <button onClick={()=>handleAll('profile')}>
            <span>Profile</span>
            </button>
            
          </li>
          <li className="flex items-center space-x-3 p-2 hover:bg-blue-700 rounded-md cursor-pointer transition-colors duration-300">
            <FaMoneyBill className="text-lg" />
            <span>Loan</span>
          </li>
          <li className="flex items-center space-x-3 p-2 hover:bg-blue-700 rounded-md cursor-pointer transition-colors duration-300">
            <FaCopy className="text-lg" />
            <span>More</span>
          </li>
        </ul>
        <div className="bottom-1/2 absolute flex flex-row items-center">
        <button onClick={Logout}>
          <div  className="text-white font-serif text-2xl"><FaPowerOff  /></div>
          <div className="text-white font-serif text-2xl">logout</div>
          </button>
        </div>

        <div className="bottom-4 absolute flex flex-row items-center">
          <div  className="text-white font-serif text-2xl"><FaCopyright /></div>
          <div className="text-white font-serif text-2xl"> softnixx</div>
        
        </div>
      </div>
    )}
    <div className="container lg:ml-0 ml-0 p-1 bg-white w-auto text-darkblue shadow-lg rounded-lg">
         <div className="flex flex-row justify-between align-middle content-center">
      <h1 className="text-4xl font-extrabold text-darkblue ">Dashboard</h1>
      <button onClick={ToggleOpen}    className={`flex items-center p-2 rounded-full ${open ? 'text-black' : 'text-white'}`}
    >
      <FaBars className={`text-xl ${open ?  'bg-red-500 shadow-lg text-white' : 'text-gray-950 bg-white shadow-lg'}`} />
      </button>
    </div>
      <div className="flex flex-row justify-around items-center">
        <h3>Sn No.</h3>
        <div>
          {loading ? "Loading..." : formatLocaleString(data?.profile?.tract_id)}
        </div>
        <div>
          <FaCopy 
            style={{ cursor: "pointer" }}
            onClick={() => copyToClipboard(data?.profile?.tract_id)} 
            className="bg-white  text-gray-950 drop-shadow-sm shadow-sm rounded-sm" 
          />
        </div>
      </div>
      <div className="bg-darkblue text-gray-950 p-6 rounded-xl shadow-md flex items-center justify-between mb-6">
        <div className="text-sm text-slate-950 font-semibold">
          <h3>Balance</h3>
          {`N${loading ? "Loading..." : formatLocaleString(data?.profile?.account_balance)}`}
        </div>
      </div>
      <div className="bg-blue-950 p-4">
    <Button onClick={handleOpen}>
      <FaPlus className="mr-2 text-lg" /> {/* Icon with margin */}
      Add Money/Deposit
    </Button>

  </div>

     
     
      <div className="flex flex-col bg-blue-950 gap-4">
      
        {loading ? <p>Loading...</p> : <Allserv />}
      </div>
      <ModalComponent open={modalOpen} onClose={handleClose} />
    </div></>
  );
};
