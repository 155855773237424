import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useAlert } from "../component/alert/useAlert";
import { useNavigate } from "react-router-dom";

export const Notificaton = () => {
    const navigate = useNavigate();
    const showMessage = useAlert();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const fetchNotifications = async () => {
        setLoading(true);
        const token = localStorage.getItem("token");
        const url = "https://www.softnixx.com/api/generate_receipt/";

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Token ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setData(result);
        } catch (error) {
            showMessage("Something went wrong!...", "error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    const headers = ["Name", "Amount", "Type"];

    return (
        <div className="container mx-auto p-4 bg-white shadow-lg rounded-lg">
            

            {loading ? (
                <div className="flex justify-center items-center">
                    <CircularProgress />
                </div>
            ) : data.length === 0 ? (
                <div className="flex flex-col items-center space-y-4">
                    <p className="text-gray-600">You have no notifications at the moment.</p>
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-100">
                            <tr>
                                {headers.map((header, index) => (
                                    <th
                                        key={index}
                                        className="py-3 px-4 text-left text-gray-600 font-semibold"
                                    >
                                        {header}
                                    </th>
                                ))}
                                <th className="py-3 px-4 text-left text-gray-600 font-semibold">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row, index) => (
                                <tr key={index} className="border-b">
                                    {headers.map((header, headerIndex) => (
                                        <td
                                            key={headerIndex}
                                            className="py-3 px-4 text-gray-800"
                                        >
                                            {row[header.toLowerCase()] || '—'}
                                        </td>
                                    ))}
                                    <td className="py-3 px-4">
                                        <button
                                            onClick={() => navigate("/receipt", { state: { id: row.id } })}
                                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                        >
                                            View
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};
