import React from "react";
import { FaMailBulk, FaWhatsapp } from "react-icons/fa";

export const Help = () => {
  const phoneNumber = '+2349061229992'; // Replace with the actual phone number in international format
  const message = 'Hello, I would like to inquire about your services.';

  // WhatsApp URL
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;


  return (
    <div className="container lg:ml-0 ml-0 p-1 bg-white w-auto text-darkblue shadow-lg rounded-lg flex flex-col items-center justify-center min-h-screen">
      <h1 className="text-xl font-mono text-center mb-4">Contact us Via the following</h1>

      <div className="flex flex-col items-center space-y-4">
      <button
      onClick={() => window.open('mailto:samuelyyyy257@gmail.com')}
      className="bg-slate-950 p-4 rounded-full flex items-center justify-center"
    >
      <FaMailBulk className="text-2xl text-white" />
    </button>
    <button
      onClick={() => window.open(whatsappUrl, '_blank')}
      className="bg-green-600 p-4 rounded-full flex items-center justify-center"
    >
      <FaWhatsapp className="text-2xl text-white" />
    </button>
      </div>
      <marquee>
        <p className=" text-red-600">If you are having transaction problem please kindly message us via WhatsApp with your reciepts ( Please send others to mail )</p>
      </marquee>
    </div>
  );
};


