import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Button } from "../Button/Button";
import { useAlert } from "../alert/useAlert";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Input } from "./Input";

export const Form = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const showAlert = useAlert();

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const response = await fetch("https://www.softnixx.com/api/login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          username: username.trim(),
          password: password.trim(),
        }),
      });

      const data = await response.json();
    
      if (response.ok) {
        setUsername("");
        setPassword("");
        localStorage.setItem("token", data.data.key); // Store token
        localStorage.setItem("profile", JSON.stringify(data.data2.profile)); // Store profile
        localStorage.setItem("user", data.data2.username);
        localStorage.setItem("staff",data.data2.is_staff);
        navigate("/home");
        showAlert('Login successful!', 'success');
      } else {
        showAlert(data.message || 'Login failed, please try again!', 'error');
      }
    } catch (error) {
      showAlert('An error occurred, please try again.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50 px-4">
      <div className="w-full max-w-sm p-6 bg-white rounded-lg shadow-lg border border-gray-200">
        <marquee className="mb-4 p-2 bg-yellow-200 text-yellow-800 rounded-md text-xs">
          Transfers to the bank are currently closed due to some policies. To get a refund, kindly message us.
        </marquee>
        <h4 className="text-2xl font-bold mb-6 text-center text-gray-800">Login</h4>
        <div className="space-y-4">
          <Input
            handleChange={handleUsernameChange}
            value={username}
            placeholder="Your username"
            label="Username"
            className="w-full"
          />
          <div className="relative">
            <Input
              handleChange={handlePasswordChange}
              value={password}
              placeholder="Password"
              label="Password"
              encrpt={showPassword ? "text" : "password"}
              className="w-full"
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {isLoading ? (
            <div className="flex justify-center py-4">
              <CircularProgress />
            </div>
          ) : (
            <div className="flex w-full justify-center py-4 ">
            <Button
              className=" bg-blue-500 w-full text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              handlSubmit={handleLogin}
              title="Submit"
            />
            </div>
          )}
        </div>
        <div className="mt-6 text-center">
          <button
            className="text-blue-500 hover:underline text-sm"
            onClick={() => window.open("https://www.softnixx.com/api/password_reset/")}
          >
            Forgot password?
          </button>
          <div className="mt-2">
            <button
              className="text-blue-500 hover:underline text-sm"
              onClick={() => navigate("/register")}
            >
              Register!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
