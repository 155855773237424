import React from "react";
import { FaShoppingBag, FaBitcoin, FaPhone, FaWifi, FaBook, FaSalesforce } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../component/alert/useAlert";

const Button = ({ children }) => (
  <button className="flex items-center p-3 bg-blue-500 text-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
    {children}
  </button>
);

export const Allserv = () => {
  const navigate = useNavigate();
  const showAlert = useAlert();
  
  const Handlecrypto = () => {
    showAlert("Web3 feature coming soon!", "success");
  };

  const HandleAll = () => {
    showAlert("The selected product has irregularity due to general server kindly check back later!", "success");
  };
  return (

    <>
    <div className="p-4 grid grid-cols-4 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
      {/* Grid Items */}
      <button
        onClick={() => window.open('https://wa.me/+2349061229992', '_blank')}
        className="flex flex-col items-center bg-blue-900 text-white p-3 rounded-lg shadow-md focus:outline-none"
      >
        <div className="bg-green-500 p-2 rounded-full mb-2">
          <FaShoppingBag className="text-lg" />
        </div>
        <p className="font-bold text-xs text-center">Contact</p>
      </button>

      <button 
        onClick={() => window.open('https://wa.me/+2349061229992', '_blank')}
        className="flex flex-col items-center bg-green-300 text-gray-800 p-3 rounded-lg shadow-md"
      >
        <div className="bg-red-300 p-2 rounded-full mb-2">
          <FaSalesforce className="text-lg" />
        </div>
        <p className="font-bold text-xs text-center">Sell Airtime</p>
      </button>

      <button
        onClick={() => navigate('airtime')}
        className="flex flex-col items-center bg-yellow-200 text-gray-800 p-3 rounded-lg shadow-md"
      >
        <div className="bg-blue-200 p-2 rounded-full mb-2">
          <FaPhone className="text-lg" />
        </div>
        <p className="font-bold text-xs text-center">Airtime</p>
      </button>

      <button
        onClick={() => navigate("data")}
        className="flex flex-col items-center bg-teal-300 text-gray-800 p-3 rounded-lg shadow-md focus:outline-none"
      >
        <div className="bg-teal-200 p-2 rounded-full mb-2">
          <FaWifi className="text-lg" />
        </div>
        <p className="font-bold text-xs text-center">Data</p>
      </button>

      {/* New Service Item: Education */}
      <button
        onClick={HandleAll}
        className="flex flex-col items-center bg-purple-300 text-gray-800 p-3 rounded-lg shadow-md"
      >
        <div className="bg-purple-200 p-2 rounded-full mb-2">
          <FaBook className="text-lg" />
        </div>
        <p className="font-bold text-xs text-center">Education</p>
      </button>

      <button
        onClick={HandleAll}
        className="flex flex-col items-center bg-blue-700 text-white p-3 rounded-lg shadow-md"
      >
        <div className="bg-yellow-200 p-2 rounded-full mb-2">
          <FaBook className="text-lg" />
        </div>
        <p className="font-bold text-xs text-center">Electricity</p>
      </button>

      {/* New Service Item: Television */}
      <button
        onClick={HandleAll}
        className="flex flex-col items-center bg-red-300 text-white p-3 rounded-lg shadow-md"
      >
        <div className="bg-purple-200 p-2 rounded-full mb-2">
          <FaBook className="text-lg" />
        </div>
        <p className="font-bold text-xs text-center">Television</p>
      </button>

    
    </div>
    

      {/* Additional Sections */}
      <div className="col-span-2 sm:col-span-2 md:col-span-3 lg:col-span-4 p-4 bg-gray-100 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold mb-2">Download our App</h3>
        <p>Stay connected and get the best experience with our mobile app.</p>
      </div>

      <div className="col-span-2 sm:col-span-2 md:col-span-3 lg:col-span-4 p-4 bg-gray-100 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold mb-2">More from Softtellex</h3>
        <p>Explore other services and offerings from Softtellex.</p>
      </div>

      <div className="col-span-2 sm:col-span-2 md:col-span-3 lg:col-span-4 p-4 bg-gray-100 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold text-gray-800 mb-3">API Softtellex</h3>
        <p className="text-gray-600">Explore our API and integrate it into your software soon. Stay tuned for more updates!</p>
      </div>
      <div className="col-span-2 sm:col-span-2 md:col-span-3 lg:col-span-4 p-4 bg-gray-100 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold mb-2">Vacancy</h3>
        <p>Check out current job openings and join our team.</p>
      </div>
    </>
  );
};
