import React, { useState } from 'react';
import './Login.css'
import { Form } from '../component/InputComp/Form'; 
import Toast from '../component/alert/Alert';
// Define the LoginScreen component as a functional component
const LoginScreen = () => {


 
  return (
    <div>
        <h1 className='h4-label'>
            Welcome Back, We can manage all your utlities payment
        </h1>
        <Form/>
    </div>
  );
};
// Export the LoginScreen component
export default LoginScreen;

