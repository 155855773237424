import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { PieChart, Pie, Cell } from 'recharts';
import { useAlert } from '../../../alert/useAlert';

const MyCharts = () => {
    const [loading, setLoading] = useState(false);
    const [lineChartData, setLineChartData] = useState([]);
    const [pieChartData, setPieChartData] = useState([]);
    const showMessage = useAlert()

    const COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'];

    const token = localStorage.getItem("token");
    useEffect(() => {
        const getDetails = async () => {
            setLoading(true);
            try {
                const itemUrl = `https://www.softnixx.com/api/receipttoday`;
                const response = await fetch(itemUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Token ${token}`,
                    },
                });

                const json = await response.json();
              

                // Transform data for Line Chart
                const lineData = json.map(item => ({
                    name: item.name, // Format date
                    uv: item.amount, // Example metric
                    pv: item.amount / 2, // Example metric
                    amt: item.amount
                }));

                // Transform data for Pie Chart
                const pieData = json.map(item => ({
                    name: item.name,
                    value: item.amount
                }));

                setLineChartData(lineData);
                setPieChartData(pieData);
            } catch (error) {
            showMessage("something went wrong","error")
            } finally {
                setLoading(false);
            }
        };

        getDetails();
    }, [token]);

    return (
        <div className="p-6 space-y-8">
            {/* Line Chart */}
            <div className="w-full bg-white p-4 rounded-lg shadow-md">
                <h3 className='text-xl font-semibold mb-4 text-center'>Total Transactions Today {lineChartData.length}</h3>
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={lineChartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                        <YAxis tick={{ fontSize: 12 }} />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="uv" stroke="#8884d8" strokeWidth={2} dot={false} />
                        <Line type="monotone" dataKey="pv" stroke="#82ca9d" strokeWidth={2} dot={false} />
                    </LineChart>
                </ResponsiveContainer>
            </div>

            {/* Pie Chart */}
            <div className="w-full bg-white p-4 rounded-lg shadow-md">
                <h3 className='text-xl font-semibold mb-4 text-center'>Utilities Distribution</h3>
                <ResponsiveContainer width="100%" height={400}>
                    <PieChart>
                        <Pie 
                            data={pieChartData} 
                            dataKey="value" 
                            nameKey="name" 
                            outerRadius={150} 
                            label
                        >
                            {pieChartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default MyCharts;
