import React, { useState } from "react";
import {
  Modal,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../component/alert/useAlert";

const ModalSettings = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [loading, setLoading] = useState(false);
  const showMessage = useAlert();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleInputChange2 = (event) => {
    setInputValue2(event.target.value);
  };

  const token = localStorage.getItem("token");
  const profile = JSON.parse(localStorage.getItem("profile"));

  const SendOver = () => {
    setLoading(true);
    if (inputValue != "" && inputValue2 !== profile.transaction_pin) {
      fetch("https://www.softnixx.com/api/update/", {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: profile.id,
          key: token,
          tran: inputValue,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            showMessage("succesfully updated!", "success");
            setLoading(false);
            setInputValue("");
          } else {
            showMessage("server error", "error");
            setLoading(false);
          }
        }).finally(()=>{
            setLoading(false)
        });
    } else if (inputValue === profile.transaction_pin) {
      showMessage("you cant use old pin", "error");
      setLoading(false);
    } else if (inputValue.length == 4) {
      showMessage("PIN 4 digit pin required!", "error");
      setLoading(false);
    }
   
  };

  const handleSubmitPin = () => {
    if (inputValue.length === 4) {
      SendOver();
    } else {
      showMessage("Please fill all four digits of PIN.", "error");
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="input-field"
            label="Enter new pin"
            type="password"
            fullWidth
            variant="outlined"
            helperText={"4 digit pin"}
            value={inputValue}
            onChange={handleInputChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Enter pin again"
            type="password"
            fullWidth
            variant="outlined"
            value={inputValue2}
            onChange={handleInputChange2}
          />
          <p>Initial password is 0000</p>
        </DialogContent>
        <DialogActions>
            {loading?null:
          <Button onClick={onClose}>Cancel</Button>
            }
          {loading ? (
            "loading...."
          ) : (
            <Button onClick={handleSubmitPin}>
              {inputValue ? "Submit" : null}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Modal>
  );
};

export default ModalSettings;
