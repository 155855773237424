
import React, { useState } from "react";
import { FaMailBulk, FaWhatsapp } from "react-icons/fa";
import { FaExplosion } from "react-icons/fa6";
import ModalSettings from "./modals";

export const Settings = () => {
    const [modalOpen, setModalOpen] = useState(false)


const handleClose = ()=>{
    setModalOpen(false)
}

const handleOpen=()=>{
    setModalOpen(true)
}

  return (
    <div className="container lg:ml-0 ml-0 p-1 bg-white w-auto text-darkblue shadow-lg rounded-lg flex flex-col items-center justify-center min-h-screen">
      <h1 className="text-2xl font-bold text-center mb-6">More Settings</h1>
      
      <div className="flex flex-col items-center space-y-4">
        <div className="flex flex-col space-y-2">
          <button
          onClick={()=>window.open("https://www.softnixx.com/api/password_rest/")}
            className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 transition duration-300"
          >
            Change Password
          </button>
          <button
          onClick={handleOpen}
            className="bg-green-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-green-700 transition duration-300"
          >
            Change Transaction PIN
          </button>
          
        </div>
        

        <div className="flex space-x-4">
          <a
            href="mailto:samuelyyyy257@gmail.com"
            className="bg-slate-950 p-4 rounded-full flex items-center justify-center text-white shadow-md hover:bg-slate-800 transition duration-300"
          >
            <FaMailBulk className="text-2xl" />
          </a>
          <a
            href="https://wa.me/1234567890" // Replace with actual phone number
            target="_blank"
            rel="noopener noreferrer"
            className="bg-green-600 p-4 rounded-full flex items-center justify-center text-white shadow-md hover:bg-green-700 transition duration-300"
          >
            <FaWhatsapp className="text-2xl" />
          </a>
          <a
            href="#"
            className="bg-purple-600 p-4 rounded-full flex items-center justify-center text-white shadow-md hover:bg-purple-700 transition duration-300"
          >
            <FaExplosion className="text-2xl" />
          </a>
        </div>
      </div>
      <ModalSettings open={modalOpen} onClose={handleClose}  />
    </div>
  );
};



